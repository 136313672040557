@import url('https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&display=swap');
@import './styles/variables';
@import './styles/mixins';

html {
    min-height: 100vh;
    font-size: 16px;
    body {
        background-color: var(--bg-main);
        position: relative;
        overflow-x: hidden;
        &::before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: radial-gradient(
                circle at var(--x, 50%) var(--y, 50%),
                rgba(29, 161, 242, 0.15) 0%,
                rgba(29, 161, 242, 0.05) 20%,
                transparent 40%
            );
            pointer-events: none;
            transition: background 0.3s ease;
        }
        font-family: $font-family-base;
        line-height: $line-height-base;
        color: var(--text-primary);
        .navbarLogo {
            width: 3rem;
        }
        .pageHeader {
            background: 
                radial-gradient(
                    circle at top left,
                    hsla(52, 80%, 60%, 0.2) 0%,
                    hsla(52, 80%, 60%, 0.102) 30%,
                    transparent 60%
                ),
                radial-gradient(
                    circle at bottom right,
                    hsla(0, 0%, 100%, 0.2) 0%,
                    hsla(0, 0%, 100%, 0.1) 30%,
                    transparent 60%
                );
            background-size: 100% 100%, 100% 100%;
            min-height: 100vh;
            margin: 0;
            position: relative;
            
            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                pointer-events: none;
            }

            &::before {
                @media (max-width: 767px) {
                    display: none;
                }
                background: radial-gradient(
                    circle 50px at var(--cursor-x, 50%) var(--cursor-y, 50%),
                    rgba(255, 255, 255, 0.06),
                    transparent 100%
                );
            }

            &::after {
                @media (max-width: 767px) {
                    display: none;
                }
                background-image: 
                    linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
                    linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
                background-size: 30px 30px;
                mask-image: radial-gradient(
                    circle 150px at var(--cursor-x, 50%) var(--cursor-y, 50%),
                    black,
                    transparent
                );
                -webkit-mask-image: radial-gradient(
                    circle 150px at var(--cursor-x, 50%) var(--cursor-y, 50%),
                    black,
                    transparent
                );
            }

            .title {
                transform: translateY(20px);
            }

            a.border {
                position: relative;
                overflow: hidden;
                transition: all 0.3s ease;
                backdrop-filter: blur(5px);
                
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -100%;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(
                        120deg,
                        transparent,
                        rgba(255, 255, 255, 0.2),
                        transparent
                    );
                    transition: 0.5s;
                }
                
                &:hover {
                    background: rgba(255, 255, 255, 0.1);
                    transform: translateY(-2px);
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
                    
                    &::before {
                        left: 100%;
                    }
                }
            }
        }            
    }
}
.w-max {
    width: max-content !important;
}
.underline {
    border-bottom: 5px solid;
    border-color: transparent;
    border-image-source: linear-gradient(90deg, rgb(9, 155, 111), rgb(10, 13, 1));
    border-image-slice: 1;
}
.qs {
    font-size: 4rem; /* Adatta secondo necessità */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 300%);
    -webkit-background-clip: text; /* Clipping del background per il testo */
    -webkit-text-fill-color: transparent; /* Riempie il testo con il gradiente */
    -webkit-text-stroke: 1px rgb(255, 255, 255); /* Contorno nero di 1px */
    text-align: center; /* Centrare il testo */
}

.media-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.media-column img {
    height: 100%;
    width: auto;
    object-fit: cover;
    border-radius: 0.5rem; /* mantiene l'angolo arrotondato */
}

.bg-accent {
    background-color: rgb(26, 122, 93) !important;
}

.bg-main {
    background-color: var(--bg-main);
}

.accent {
    color: rgb(21, 92, 71) !important; 
}

.bg-light-custom {
    background-color: #dee0de83;
}
.bg-info-custom {
    background-color: #8babe75e;
}
.text-info-custom {
    color: #2155bc;
}
.blur {
    backdrop-filter: blur(30px);
}

.h-max {
    height:inherit;
}
.title {
    font-family: "Afacad", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    margin-bottom: $spacing-unit;
}

.container {
    padding: $container-padding;
}

// Common link styles
.btn-link {
    color: var(--text-primary);
    text-decoration: none;
    padding: 0.5rem 1rem;
    border: 1px solid var(--text-primary);
    @include hover-lift;
}

// Glass effect for cards and navbars
.glass {
    @include glass-effect;
}

// Responsive containers
.container {
    @include responsive(sm) {
        max-width: 540px;
    }
    @include responsive(md) {
        max-width: 720px;
    }
    @include responsive(lg) {
        max-width: 960px;
    }
    @include responsive(xl) {
        max-width: 1140px;
    }
}

// Animations
@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes slideIn {
  from { transform: translateX(-100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

.animate-pulse {
  animation: pulse 2s ease-in-out infinite;
}

.animate-slide-in {
  animation: slideIn 0.5s ease-out forwards;
}

// Enhanced stat items
.stat-item {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 1rem;
  padding: 1.5rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.2);
  
  &:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.3);
  }
  
  .badge {
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    
    &:hover {
      transform: scale(1.1);
      background: rgba(255, 255, 255, 0.15);
    }
  }
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: rgba(255, 255, 255, 0.95);
    
    &.updating {
      animation: numberUpdate 0.5s ease;
    }
  }
}

@keyframes numberUpdate {
  0% {
    transform: scale(1);
    color: inherit;
  }
  50% {
    transform: scale(1.1);
    color: var(--bs-success);
  }
  100% {
    transform: scale(1);
    color: inherit;
  }
}

// Enhanced Scroll Indicator
.scroll-indicator {
  .mouse {
    position: relative;
    width: 2rem;
    height: 3.5rem;
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 2rem;
    
    &::before {
      content: '';
      position: absolute;
      top: 0.5rem;
      left: 50%;
      width: 0.5rem;
      height: 0.5rem;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      transform: translateX(-50%);
      animation: scrollWheel 2s infinite;
    }
  }
}

@keyframes scrollWheel {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 1.5rem);
  }
}

// Mission Component Styles
.mission-point {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  
  &:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.2);
    
    .mission-icon {
      transform: scale(1.2);
      color: var(--bs-primary);
    }
  }
  
  .mission-icon {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
  }
}

// Buy Component Styles
.buy-steps {
    .col-12 {
        @media (max-width: 767px) {
            margin-bottom: 2rem;
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .buy-step {
        background: var(--bg-adding);
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 1rem;
        transition: all 0.3s ease;
        height: 100%;
        padding: 2rem 1.5rem;

        &:hover {
            transform: translateY(-5px);
            background: rgba(255, 255, 255, 0.15);
        }

        &.active {
            border-color: var(--accent-color);
            background: rgba(255, 255, 255, 0.2);
        }

        .step-icon-wrapper {
            position: relative;
            display: inline-block;
            margin-bottom: 1.5rem;

            .step-number {
                position: absolute;
                top: -5px;
                right: -5px;
                width: 24px;
                height: 24px;
                background: var(--accent-color);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.8rem;
                font-weight: bold;
            }

            .icon-circle {
                width: 80px;
                height: 80px;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;

                .step-icon {
                    font-size: 2rem;
                    color: var(--text-primary);
                }
            }
        }

        h5 {
            margin: 0;
            font-family: "Afacad", sans-serif;
            font-size: 1.25rem;
        }

        .btn-link {
            display: inline-block;
            margin-top: auto;
            font-size: 0.9rem;
            padding: 0.4rem 0.8rem;
            border-radius: 0.5rem;
            
            &:hover {
                background: var(--accent-color);
                border-color: var(--accent-color);
            }
        }

        @include responsive(md) {
            margin: 0.5rem;
        }
    }
}

.crypto-stats {
    @media (max-width: 767px) {
        left: 0;
        right: 0;
        margin: 0 !important;
        padding: 0 1rem;
        width: 100%;
        
        .mobile-stats {
            background: rgba(0, 0, 0, 0.6);
            border-radius: 1.25rem;
            padding: 1.5rem;
            display: grid;
            gap: 1.5rem;
            
            .mobile-stat-item {
                background: rgba(255, 255, 255, 0.05);
                border-radius: 1rem;
                padding: 1.25rem;
                
                h3 {
                    font-size: 1.35rem;
                    font-weight: 600;
                    letter-spacing: -0.02em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
                .badge {
                    font-size: 0.8rem;
                    padding: 0.4rem 0.75rem;
                    font-weight: 500;
                    white-space: nowrap;
                    align-self: center;
                }
                
                &.loading {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }
    
    .stat-item {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 1rem;
        transition: all 0.3s ease;
        padding: 1rem;
        
        &.loading {
            opacity: 0.5;
            pointer-events: none;
        }
        
        h3 {
            font-size: 1.2rem;
            
            .badge {
                font-size: 0.8rem;
                padding: 0.25rem 0.5rem;
            }
        }
    }
}

.faq-section {
  margin-top: 4rem;
  
  .accordion {
    --bs-accordion-bg: transparent;
    --bs-accordion-border-color: rgba(255, 255, 255, 0.1);
    --bs-accordion-btn-color: var(--text-primary);
    --bs-accordion-btn-bg: transparent;
    --bs-accordion-active-bg: transparent;
    --bs-accordion-active-color: var(--text-primary);
    --bs-accordion-btn-focus-border-color: var(--accent-color);
    --bs-accordion-btn-focus-box-shadow: none;

    .accordion-item {
      background: var(--bg-adding);
      backdrop-filter: blur(10px);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 1rem;
      margin-bottom: 1rem;
      overflow: hidden;
      transition: all 0.3s ease;

      &:hover {
        border-color: var(--accent-color);
        transform: translateY(-2px);
      }

      .accordion-button {
        background: transparent;
        color: var(--text-primary);
        font-family: "Afacad", sans-serif;
        font-weight: 500;
        padding: 1.5rem;
        
        &:not(.collapsed) {
          box-shadow: none;
          color: var(--accent-color);
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        &::after {
          filter: invert(1);
        }

        &:focus {
          box-shadow: none;
        }
      }

      .accordion-body {
        background: rgba(255, 255, 255, 0.05);
        color: var(--text-secondary);
        padding: 1.5rem;
        line-height: 1.6;
      }
    }
  }

  h5 {
    font-family: "Afacad", sans-serif;
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }
}

// Animation
.animate-slide-in {
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Enhanced Navigation Styles
.navbar {
  transition: all 0.3s ease;
  
  &.scrolled {
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
  }
  
  .nav-link-hover {
    transition: all 0.3s ease;
    
    &:hover {
      transform: translateY(-2px);
      
      .icon-hover {
        transform: scale(1.2);
      }
    }
    
    &.active {
      color: var(--bs-primary) !important;
      
      .nav-link-indicator {
        width: 100%;
        opacity: 1;
      }
    }
    
    .nav-link-indicator {
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      background: var(--bs-primary);
      transition: all 0.3s ease;
      opacity: 0;
    }
    
    .icon-hover {
      display: inline-block;
      transition: transform 0.3s ease;
    }
  }
}

.transition-all {
  transition: all 0.3s ease;
}

// Enhanced Stats Items
.stat-item {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 1.5rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  &:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.2);
  }
  
  .badge {
    transition: all 0.3s ease;
    
    &:hover {
      transform: scale(1.1);
    }
  }
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    
    &.updating {
      animation: numberUpdate 0.5s ease;
    }
  }
}

@keyframes numberUpdate {
  0% {
    transform: scale(1);
    color: inherit;
  }
  50% {
    transform: scale(1.1);
    color: var(--bs-success);
  }
  100% {
    transform: scale(1);
    color: inherit;
  }
}

// Enhanced Scroll Indicator
.scroll-indicator {
  .mouse {
    position: relative;
    width: 2rem;
    height: 3.5rem;
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 2rem;
    
    &::before {
      content: '';
      position: absolute;
      top: 0.5rem;
      left: 50%;
      width: 0.5rem;
      height: 0.5rem;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      transform: translateX(-50%);
      animation: scrollWheel 2s infinite;
    }
  }
}

@keyframes scrollWheel {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 1.5rem);
  }
}

// Buy Section Styles
.buy-section {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  min-height: 100vh;
  padding: 5rem 0;
  
  .display-4 {
    font-weight: 700;
    margin-bottom: 1.5rem;
    
    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }
  
  .lead {
    font-size: 1.2rem;
    opacity: 0.8;
    
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
}

.steps-container {
  .buy-step {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    transition: all 0.3s ease;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.1);
    
    @media (max-width: 768px) {
      padding: 1rem !important;
    }
    
    &:hover, &.active {
      background: rgba(255, 255, 255, 0.15);
      transform: translateX(10px);
      border-color: rgba(255, 255, 255, 0.2);
      
      .step-number {
        background: var(--bs-primary);
        color: white;
      }
      
      h4 {
        color: var(--bs-primary);
      }
    }
    
    .step-number {
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      transition: all 0.3s ease;
      
      @media (max-width: 768px) {
        width: 32px;
        height: 32px;
        font-size: 0.9rem;
      }
    }
    
    h4 {
      font-size: 1.1rem;
      transition: color 0.3s ease;
      
      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
    
    p {
      font-size: 0.9rem;
      
      @media (max-width: 768px) {
        font-size: 0.8rem;
      }
    }
  }
}

.step-details {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  height: 100%;
  min-height: 300px;
  
  @media (max-width: 992px) {
    margin-top: 2rem;
  }
  
  .step-icon {
    font-size: 2.5rem;
    color: var(--bs-primary);
    margin-bottom: 1.5rem;
    
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    
    @media (max-width: 768px) {
      font-size: 1.3rem;
    }
  }
  
  p {
    opacity: 0.8;
    margin-bottom: 1.5rem;
    
    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
}

.faq-section {
  margin-top: 5rem;
  
  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
    
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
  
  .custom-accordion {
    max-width: 800px;
    margin: 0 auto;
    
    .accordion-item {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      overflow: hidden;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      .accordion-button {
        background: transparent;
        color: white;
        
        &:not(.collapsed) {
          background: rgba(255, 255, 255, 0.15);
        }
        
        &:focus {
          box-shadow: none;
        }
        
        &::after {
          filter: invert(1);
        }
        
        @media (max-width: 768px) {
          padding: 1rem;
          font-size: 0.9rem;
        }
      }
      
      .accordion-body {
        background: transparent;
        color: rgba(255, 255, 255, 0.8);
        padding: 1.25rem;
        font-size: 0.95rem;
        line-height: 1.6;
        
        @media (max-width: 768px) {
          padding: 1rem;
          font-size: 0.85rem;
        }
      }
    }
  }
}

.btn-custom {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
  
  &.primary {
    background: var(--bs-primary);
    color: white;
    border: none;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 5px 15px rgba(var(--bs-primary-rgb), 0.3);
    }
  }
  
  &.secondary {
    background: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      transform: translateY(-2px);
    }
  }
}

// Image Container
.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  
  &.hover-scale {
    img {
      transition: all 0.5s ease;
    }
    
    &:hover {
      img {
        transform: scale(1.05);
      }
      
      .image-overlay {
        opacity: 1;
      }
    }
  }
  
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease;
    
    .overlay-text {
      color: white;
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

// Custom Accordion
.custom-accordion {
  .accordion-item {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 0.5rem;
    
    .accordion-button {
      background: transparent;
      color: white;
      
      &:not(.collapsed) {
        background: rgba(255, 255, 255, 0.15);
      }
      
      &:focus {
        box-shadow: none;
      }
      
      &::after {
        filter: invert(1);
      }
    }
    
    .accordion-body {
      background: transparent;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

// Link Styles
.btn-link {
  color: var(--bs-primary);
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  
  &:hover {
    color: var(--bs-primary);
    text-decoration: underline;
  }
}

// Partner Modal
.partner-modal {
  .modal-content {
    background: rgba(33, 37, 41, 0.95);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    
    .modal-header {
      border-bottom-color: rgba(255, 255, 255, 0.1);
      
      .modal-title {
        color: white;
      }
      
      .btn-close {
        filter: invert(1);
      }
    }
    
    .modal-body {
      color: white;
      
      .custom-list {
        list-style: none;
        padding-left: 0;
        
        li {
          padding: 0.5rem 0;
          padding-left: 1.5rem;
          position: relative;
          
          &::before {
            content: '✓';
            position: absolute;
            left: 0;
            color: var(--bs-primary);
          }
        }
      }
    }
    
    .modal-footer {
      border-top-color: rgba(255, 255, 255, 0.1);
    }
  }
}

// Buy Section Styles
.buy-section {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  min-height: 100vh;
  padding: 5rem 0;
  
  .display-4 {
    font-weight: 700;
    margin-bottom: 1.5rem;
    
    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }
  
  .lead {
    font-size: 1.2rem;
    opacity: 0.8;
    
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
}

.steps-container {
  .buy-step {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    transition: all 0.3s ease;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.1);
    
    @media (max-width: 768px) {
      padding: 1rem !important;
    }
    
    &:hover, &.active {
      background: rgba(255, 255, 255, 0.15);
      transform: translateX(10px);
      border-color: rgba(255, 255, 255, 0.2);
      
      .step-number {
        background: var(--bs-primary);
        color: white;
      }
      
      h4 {
        color: var(--bs-primary);
      }
    }
    
    .step-number {
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      transition: all 0.3s ease;
      
      @media (max-width: 768px) {
        width: 32px;
        height: 32px;
        font-size: 0.9rem;
      }
    }
    
    h4 {
      font-size: 1.1rem;
      transition: color 0.3s ease;
      
      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
    
    p {
      font-size: 0.9rem;
      
      @media (max-width: 768px) {
        font-size: 0.8rem;
      }
    }
  }
}

.step-details {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  height: 100%;
  min-height: 300px;
  
  @media (max-width: 992px) {
    margin-top: 2rem;
  }
  
  .step-icon {
    font-size: 2.5rem;
    color: var(--bs-primary);
    margin-bottom: 1.5rem;
    
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    
    @media (max-width: 768px) {
      font-size: 1.3rem;
    }
  }
  
  p {
    opacity: 0.8;
    margin-bottom: 1.5rem;
    
    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
}

.faq-section {
  margin-top: 5rem;
  
  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
    
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
  
  .custom-accordion {
    max-width: 800px;
    margin: 0 auto;
    
    .accordion-item {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      overflow: hidden;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      .accordion-button {
        background: transparent;
        color: white;
        
        &:not(.collapsed) {
          background: rgba(255, 255, 255, 0.15);
        }
        
        &:focus {
          box-shadow: none;
        }
        
        &::after {
          filter: invert(1);
        }
        
        @media (max-width: 768px) {
          padding: 1rem;
          font-size: 0.9rem;
        }
      }
      
      .accordion-body {
        background: transparent;
        color: rgba(255, 255, 255, 0.8);
        padding: 1.25rem;
        font-size: 0.95rem;
        line-height: 1.6;
        
        @media (max-width: 768px) {
          padding: 1rem;
          font-size: 0.85rem;
        }
      }
    }
  }
}

.btn-custom {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
  
  &.primary {
    background: var(--bs-primary);
    color: white;
    border: none;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 5px 15px rgba(var(--bs-primary-rgb), 0.3);
    }
  }
  
  &.secondary {
    background: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      transform: translateY(-2px);
    }
  }
}