.wiki-header {
    background: radial-gradient(circle at top left, hsla(52, 80%, 60%, 0.2) 0%, hsla(52, 80%, 60%, 0.102) 30%, transparent 60%), 
                radial-gradient(circle at bottom right, hsla(0, 0%, 100%, 0.2) 0%, hsla(0, 0%, 100%, 0.1) 30%, transparent 60%);
    background-size: 100% 100%, 100% 100%;
}

.wiki-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: radial-gradient(circle 50px at var(--cursor-x, 50%) var(--cursor-y, 50%), rgba(255, 255, 255, 0.06), transparent 100%);
}

.wiki-header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
                      linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
    background-size: 30px 30px;
    mask-image: radial-gradient(circle 150px at var(--cursor-x, 50%) var(--cursor-y, 50%), black, transparent);
    -webkit-mask-image: radial-gradient(circle 150px at var(--cursor-x, 50%) var(--cursor-y, 50%), black, transparent);
}

.wiki-content {
    background-color: var(--bg-main);
    color: var(--text-primary);
    min-height: 100vh;
}

.featured-box {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
    backdrop-filter: blur(10px);
}

.feature-item {
    text-align: center;
    padding: 1.5rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
}

.feature-icon {
    font-size: 2rem;
    color: var(--accent-color);
}

.course-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    padding: 1.5rem;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
    height: 100%;
}

.course-card:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.2);
}

.course-icon {
    font-size: 2rem;
    color: var(--accent-color);
    transition: all 0.3s ease;
}

.course-card:hover .course-icon {
    transform: scale(1.2);
    color: var(--bs-primary);
}

.topics-list {
    list-style: none;
    padding-left: 0;
}

.topics-list li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
}

.topics-list li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: var(--accent-color);
}

.badge {
    padding: 0.35rem 0.75rem;
    border-radius: 1rem;
    font-size: 0.75rem;
    font-weight: 500;
}

.badge.beginner {
    background-color: rgba(40, 167, 69, 0.2);
    color: #28a745;
}

.badge.intermediate {
    background-color: rgba(255, 193, 7, 0.2);
    color: #ffc107;
}

.badge.advanced {
    background-color: rgba(220, 53, 69, 0.2);
    color: #dc3545;
}

.btn-outline-light {
    border-color: rgba(255, 255, 255, 0.2);
    color: var(--text-primary);
    transition: all 0.3s ease;
}

.btn-outline-light:hover {
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.3);
    transform: translateY(-2px);
}
