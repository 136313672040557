@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin responsive($breakpoint) {
    @if $breakpoint == sm {
        @media (min-width: $breakpoint-sm) { @content; }
    }
    @else if $breakpoint == md {
        @media (min-width: $breakpoint-md) { @content; }
    }
    @else if $breakpoint == lg {
        @media (min-width: $breakpoint-lg) { @content; }
    }
    @else if $breakpoint == xl {
        @media (min-width: $breakpoint-xl) { @content; }
    }
}

@mixin hover-lift {
    transition: transform 0.3s ease;
    &:hover {
        transform: translateY(-2px);
    }
}

@mixin glass-effect {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}
