.tweet-section {
    padding: 4rem 0;
    background-color: var(--bg-main);
}

.tweet-card {
    background: var(--bg-adding);
    border-radius: 1rem;
    padding: 1.5rem;
    height: 100%;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
    }

    .tweet-header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        .profile-image {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 1rem;
        }

        .user-info {
            .name {
                margin: 0;
                color: var(--text-primary);
            }

            .username {
                margin: 0;
                color: var(--text-secondary);
                font-size: 0.9rem;
            }
        }
    }

    .tweet-text {
        margin-bottom: 1rem;
        color: var(--text-primary);
    }

    .tweet-media {
        width: 100%;
        border-radius: 0.5rem;
        margin-bottom: 1rem;
    }

    .tweet-date {
        color: var(--text-secondary);
        font-size: 0.9rem;
        margin-bottom: 1rem;
    }

    .tweet-link {
        display: inline-block;
        color: var(--text-primary);
        text-decoration: none;
        padding: 0.5rem 1rem;
        border: 1px solid var(--text-primary);
        border-radius: 0.25rem;
        transition: all 0.3s ease;

        &:hover {
            background: rgba(255, 255, 255, 0.1);
            color: var(--text-primary);
        }
    }
}

.tweets-masonry {
    column-count: 1;
    column-gap: 2rem;
    width: 100%;
    
    .tweet-item {
        break-inside: avoid;
        margin-bottom: 2rem;
        display: inline-block;
        width: 100%;

        > div {
            min-width: 0;
            width: 100%;
        }

        iframe {
            width: 100% !important;
            min-width: 0 !important;
        }
    }
}

@media (min-width: 768px) {
    .tweets-masonry {
        column-count: 2;
    }
}

@media (min-width: 992px) {
    .tweets-masonry {
        column-count: 3;
    }
}
