@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&display=swap");
:root {
  --bg-main: #000000;
  --bg-adding: rgba(255, 255, 255, 0.1);
  --text-primary: #ffffff;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --accent-color: #1da1f2;
}

html {
  min-height: 100vh;
  font-size: 16px;
}
html body {
  background-color: var(--bg-main);
  position: relative;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  line-height: 1.5;
  color: var(--text-primary);
}
html body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at var(--x, 50%) var(--y, 50%), rgba(29, 161, 242, 0.15) 0%, rgba(29, 161, 242, 0.05) 20%, transparent 40%);
  pointer-events: none;
  transition: background 0.3s ease;
}
html body .navbarLogo {
  width: 3rem;
}
html body .pageHeader {
  background: radial-gradient(circle at top left, hsla(52, 80%, 60%, 0.2) 0%, hsla(52, 80%, 60%, 0.102) 30%, transparent 60%), radial-gradient(circle at bottom right, hsla(0, 0%, 100%, 0.2) 0%, hsla(0, 0%, 100%, 0.1) 30%, transparent 60%);
  background-size: 100% 100%, 100% 100%;
  min-height: 100vh;
  margin: 0;
  position: relative;
}
html body .pageHeader::before, html body .pageHeader::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
html body .pageHeader::before {
  background: radial-gradient(circle 50px at var(--cursor-x, 50%) var(--cursor-y, 50%), rgba(255, 255, 255, 0.06), transparent 100%);
}
@media (max-width: 767px) {
  html body .pageHeader::before {
    display: none;
  }
}
html body .pageHeader::after {
  background-image: linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 30px 30px;
  mask-image: radial-gradient(circle 150px at var(--cursor-x, 50%) var(--cursor-y, 50%), black, transparent);
  -webkit-mask-image: radial-gradient(circle 150px at var(--cursor-x, 50%) var(--cursor-y, 50%), black, transparent);
}
@media (max-width: 767px) {
  html body .pageHeader::after {
    display: none;
  }
}
html body .pageHeader .title {
  transform: translateY(20px);
}
html body .pageHeader a.border {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
html body .pageHeader a.border::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: 0.5s;
}
html body .pageHeader a.border:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
html body .pageHeader a.border:hover::before {
  left: 100%;
}

.w-max {
  width: -moz-max-content !important;
  width: max-content !important;
}

.underline {
  border-bottom: 5px solid;
  border-color: transparent;
  border-image-source: linear-gradient(90deg, rgb(9, 155, 111), rgb(10, 13, 1));
  border-image-slice: 1;
}

.qs {
  font-size: 4rem; /* Adatta secondo necessità */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 300%);
  -webkit-background-clip: text; /* Clipping del background per il testo */
  -webkit-text-fill-color: transparent; /* Riempie il testo con il gradiente */
  -webkit-text-stroke: 1px rgb(255, 255, 255); /* Contorno nero di 1px */
  text-align: center; /* Centrare il testo */
}

.media-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.media-column img {
  height: 100%;
  width: auto;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.5rem; /* mantiene l'angolo arrotondato */
}

.bg-accent {
  background-color: rgb(26, 122, 93) !important;
}

.bg-main {
  background-color: var(--bg-main);
}

.accent {
  color: rgb(21, 92, 71) !important;
}

.bg-light-custom {
  background-color: rgba(222, 224, 222, 0.5137254902);
}

.bg-info-custom {
  background-color: rgba(139, 171, 231, 0.368627451);
}

.text-info-custom {
  color: #2155bc;
}

.blur {
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
}

.h-max {
  height: inherit;
}

.title {
  font-family: "Afacad", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin-bottom: 1rem;
}

.container {
  padding: 2rem;
}

.btn-link {
  color: var(--text-primary);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid var(--text-primary);
  transition: transform 0.3s ease;
}
.btn-link:hover {
  transform: translateY(-2px);
}

.glass {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.animate-float {
  animation: float 3s ease-in-out infinite;
}

.animate-pulse {
  animation: pulse 2s ease-in-out infinite;
}

.animate-slide-in {
  animation: slideIn 0.5s ease-out forwards;
}

.stat-item {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 1rem;
  padding: 1.5rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.2);
}
.stat-item:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.3);
}
.stat-item .badge {
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.stat-item .badge:hover {
  transform: scale(1.1);
  background: rgba(255, 255, 255, 0.15);
}
.stat-item h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.95);
}
.stat-item h3.updating {
  animation: numberUpdate 0.5s ease;
}

@keyframes numberUpdate {
  0% {
    transform: scale(1);
    color: inherit;
  }
  50% {
    transform: scale(1.1);
    color: var(--bs-success);
  }
  100% {
    transform: scale(1);
    color: inherit;
  }
}
.scroll-indicator .mouse {
  position: relative;
  width: 2rem;
  height: 3.5rem;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 2rem;
}
.scroll-indicator .mouse::before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  transform: translateX(-50%);
  animation: scrollWheel 2s infinite;
}

@keyframes scrollWheel {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 1.5rem);
  }
}
.mission-point {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
}
.mission-point:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.2);
}
.mission-point:hover .mission-icon {
  transform: scale(1.2);
  color: var(--bs-primary);
}
.mission-point .mission-icon {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
}

@media (max-width: 767px) {
  .buy-steps .col-12 {
    margin-bottom: 2rem;
  }
  .buy-steps .col-12:last-child {
    margin-bottom: 0;
  }
}
.buy-steps .buy-step {
  background: var(--bg-adding);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  transition: all 0.3s ease;
  height: 100%;
  padding: 2rem 1.5rem;
}
.buy-steps .buy-step:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
}
.buy-steps .buy-step.active {
  border-color: var(--accent-color);
  background: rgba(255, 255, 255, 0.2);
}
.buy-steps .buy-step .step-icon-wrapper {
  position: relative;
  display: inline-block;
  margin-bottom: 1.5rem;
}
.buy-steps .buy-step .step-icon-wrapper .step-number {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 24px;
  height: 24px;
  background: var(--accent-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
}
.buy-steps .buy-step .step-icon-wrapper .icon-circle {
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.buy-steps .buy-step .step-icon-wrapper .icon-circle .step-icon {
  font-size: 2rem;
  color: var(--text-primary);
}
.buy-steps .buy-step h5 {
  margin: 0;
  font-family: "Afacad", sans-serif;
  font-size: 1.25rem;
}
.buy-steps .buy-step .btn-link {
  display: inline-block;
  margin-top: auto;
  font-size: 0.9rem;
  padding: 0.4rem 0.8rem;
  border-radius: 0.5rem;
}
.buy-steps .buy-step .btn-link:hover {
  background: var(--accent-color);
  border-color: var(--accent-color);
}
@media (min-width: 768px) {
  .buy-steps .buy-step {
    margin: 0.5rem;
  }
}

@media (max-width: 767px) {
  .crypto-stats {
    left: 0;
    right: 0;
    margin: 0 !important;
    padding: 0 1rem;
    width: 100%;
  }
  .crypto-stats .mobile-stats {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 1.25rem;
    padding: 1.5rem;
    display: grid;
    gap: 1.5rem;
  }
  .crypto-stats .mobile-stats .mobile-stat-item {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
    padding: 1.25rem;
  }
  .crypto-stats .mobile-stats .mobile-stat-item h3 {
    font-size: 1.35rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .crypto-stats .mobile-stats .mobile-stat-item .badge {
    font-size: 0.8rem;
    padding: 0.4rem 0.75rem;
    font-weight: 500;
    white-space: nowrap;
    align-self: center;
  }
  .crypto-stats .mobile-stats .mobile-stat-item.loading {
    opacity: 0.5;
    pointer-events: none;
  }
}
.crypto-stats .stat-item {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  transition: all 0.3s ease;
  padding: 1rem;
}
.crypto-stats .stat-item.loading {
  opacity: 0.5;
  pointer-events: none;
}
.crypto-stats .stat-item h3 {
  font-size: 1.2rem;
}
.crypto-stats .stat-item h3 .badge {
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
}

.faq-section {
  margin-top: 4rem;
}
.faq-section .accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-border-color: rgba(255, 255, 255, 0.1);
  --bs-accordion-btn-color: var(--text-primary);
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-active-bg: transparent;
  --bs-accordion-active-color: var(--text-primary);
  --bs-accordion-btn-focus-border-color: var(--accent-color);
  --bs-accordion-btn-focus-box-shadow: none;
}
.faq-section .accordion .accordion-item {
  background: var(--bg-adding);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: all 0.3s ease;
}
.faq-section .accordion .accordion-item:hover {
  border-color: var(--accent-color);
  transform: translateY(-2px);
}
.faq-section .accordion .accordion-item .accordion-button {
  background: transparent;
  color: var(--text-primary);
  font-family: "Afacad", sans-serif;
  font-weight: 500;
  padding: 1.5rem;
}
.faq-section .accordion .accordion-item .accordion-button:not(.collapsed) {
  box-shadow: none;
  color: var(--accent-color);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.faq-section .accordion .accordion-item .accordion-button::after {
  filter: invert(1);
}
.faq-section .accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.faq-section .accordion .accordion-item .accordion-body {
  background: rgba(255, 255, 255, 0.05);
  color: var(--text-secondary);
  padding: 1.5rem;
  line-height: 1.6;
}
.faq-section h5 {
  font-family: "Afacad", sans-serif;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.animate-slide-in {
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.navbar {
  transition: all 0.3s ease;
}
.navbar.scrolled {
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.navbar .nav-link-hover {
  transition: all 0.3s ease;
}
.navbar .nav-link-hover:hover {
  transform: translateY(-2px);
}
.navbar .nav-link-hover:hover .icon-hover {
  transform: scale(1.2);
}
.navbar .nav-link-hover.active {
  color: var(--bs-primary) !important;
}
.navbar .nav-link-hover.active .nav-link-indicator {
  width: 100%;
  opacity: 1;
}
.navbar .nav-link-hover .nav-link-indicator {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--bs-primary);
  transition: all 0.3s ease;
  opacity: 0;
}
.navbar .nav-link-hover .icon-hover {
  display: inline-block;
  transition: transform 0.3s ease;
}

.transition-all {
  transition: all 0.3s ease;
}

.stat-item {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 1.5rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.stat-item:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.2);
}
.stat-item .badge {
  transition: all 0.3s ease;
}
.stat-item .badge:hover {
  transform: scale(1.1);
}
.stat-item h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.stat-item h3.updating {
  animation: numberUpdate 0.5s ease;
}

@keyframes numberUpdate {
  0% {
    transform: scale(1);
    color: inherit;
  }
  50% {
    transform: scale(1.1);
    color: var(--bs-success);
  }
  100% {
    transform: scale(1);
    color: inherit;
  }
}
.scroll-indicator .mouse {
  position: relative;
  width: 2rem;
  height: 3.5rem;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 2rem;
}
.scroll-indicator .mouse::before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  transform: translateX(-50%);
  animation: scrollWheel 2s infinite;
}

@keyframes scrollWheel {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 1.5rem);
  }
}
.buy-section {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  min-height: 100vh;
  padding: 5rem 0;
}
.buy-section .display-4 {
  font-weight: 700;
  margin-bottom: 1.5rem;
}
@media (max-width: 768px) {
  .buy-section .display-4 {
    font-size: 2.5rem;
  }
}
.buy-section .lead {
  font-size: 1.2rem;
  opacity: 0.8;
}
@media (max-width: 768px) {
  .buy-section .lead {
    font-size: 1rem;
  }
}

.steps-container .buy-step {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 1rem;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 768px) {
  .steps-container .buy-step {
    padding: 1rem !important;
  }
}
.steps-container .buy-step:hover, .steps-container .buy-step.active {
  background: rgba(255, 255, 255, 0.15);
  transform: translateX(10px);
  border-color: rgba(255, 255, 255, 0.2);
}
.steps-container .buy-step:hover .step-number, .steps-container .buy-step.active .step-number {
  background: var(--bs-primary);
  color: white;
}
.steps-container .buy-step:hover h4, .steps-container .buy-step.active h4 {
  color: var(--bs-primary);
}
.steps-container .buy-step .step-number {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: all 0.3s ease;
}
@media (max-width: 768px) {
  .steps-container .buy-step .step-number {
    width: 32px;
    height: 32px;
    font-size: 0.9rem;
  }
}
.steps-container .buy-step h4 {
  font-size: 1.1rem;
  transition: color 0.3s ease;
}
@media (max-width: 768px) {
  .steps-container .buy-step h4 {
    font-size: 1rem;
  }
}
.steps-container .buy-step p {
  font-size: 0.9rem;
}
@media (max-width: 768px) {
  .steps-container .buy-step p {
    font-size: 0.8rem;
  }
}

.step-details {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 1rem;
  height: 100%;
  min-height: 300px;
}
@media (max-width: 992px) {
  .step-details {
    margin-top: 2rem;
  }
}
.step-details .step-icon {
  font-size: 2.5rem;
  color: var(--bs-primary);
  margin-bottom: 1.5rem;
}
@media (max-width: 768px) {
  .step-details .step-icon {
    font-size: 2rem;
  }
}
.step-details h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .step-details h3 {
    font-size: 1.3rem;
  }
}
.step-details p {
  opacity: 0.8;
  margin-bottom: 1.5rem;
}
@media (max-width: 768px) {
  .step-details p {
    font-size: 0.9rem;
  }
}

.faq-section {
  margin-top: 5rem;
}
.faq-section h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  .faq-section h3 {
    font-size: 1.5rem;
  }
}
.faq-section .custom-accordion {
  max-width: 800px;
  margin: 0 auto;
}
.faq-section .custom-accordion .accordion-item {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
}
.faq-section .custom-accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-section .custom-accordion .accordion-item .accordion-button {
  background: transparent;
  color: white;
}
.faq-section .custom-accordion .accordion-item .accordion-button:not(.collapsed) {
  background: rgba(255, 255, 255, 0.15);
}
.faq-section .custom-accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.faq-section .custom-accordion .accordion-item .accordion-button::after {
  filter: invert(1);
}
@media (max-width: 768px) {
  .faq-section .custom-accordion .accordion-item .accordion-button {
    padding: 1rem;
    font-size: 0.9rem;
  }
}
.faq-section .custom-accordion .accordion-item .accordion-body {
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  padding: 1.25rem;
  font-size: 0.95rem;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .faq-section .custom-accordion .accordion-item .accordion-body {
    padding: 1rem;
    font-size: 0.85rem;
  }
}

.btn-custom {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .btn-custom {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}
.btn-custom.primary {
  background: var(--bs-primary);
  color: white;
  border: none;
}
.btn-custom.primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(var(--bs-primary-rgb), 0.3);
}
.btn-custom.secondary {
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.btn-custom.secondary:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
}
.image-container.hover-scale img {
  transition: all 0.5s ease;
}
.image-container.hover-scale:hover img {
  transform: scale(1.05);
}
.image-container.hover-scale:hover .image-overlay {
  opacity: 1;
}
.image-container .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
}
.image-container .image-overlay .overlay-text {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.custom-accordion .accordion-item {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 0.5rem;
}
.custom-accordion .accordion-item .accordion-button {
  background: transparent;
  color: white;
}
.custom-accordion .accordion-item .accordion-button:not(.collapsed) {
  background: rgba(255, 255, 255, 0.15);
}
.custom-accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.custom-accordion .accordion-item .accordion-button::after {
  filter: invert(1);
}
.custom-accordion .accordion-item .accordion-body {
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
}

.btn-link {
  color: var(--bs-primary);
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}
.btn-link:hover {
  color: var(--bs-primary);
  text-decoration: underline;
}

.partner-modal .modal-content {
  background: rgba(33, 37, 41, 0.95);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.partner-modal .modal-content .modal-header {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}
.partner-modal .modal-content .modal-header .modal-title {
  color: white;
}
.partner-modal .modal-content .modal-header .btn-close {
  filter: invert(1);
}
.partner-modal .modal-content .modal-body {
  color: white;
}
.partner-modal .modal-content .modal-body .custom-list {
  list-style: none;
  padding-left: 0;
}
.partner-modal .modal-content .modal-body .custom-list li {
  padding: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}
.partner-modal .modal-content .modal-body .custom-list li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: var(--bs-primary);
}
.partner-modal .modal-content .modal-footer {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.buy-section {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  min-height: 100vh;
  padding: 5rem 0;
}
.buy-section .display-4 {
  font-weight: 700;
  margin-bottom: 1.5rem;
}
@media (max-width: 768px) {
  .buy-section .display-4 {
    font-size: 2.5rem;
  }
}
.buy-section .lead {
  font-size: 1.2rem;
  opacity: 0.8;
}
@media (max-width: 768px) {
  .buy-section .lead {
    font-size: 1rem;
  }
}

.steps-container .buy-step {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 1rem;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 768px) {
  .steps-container .buy-step {
    padding: 1rem !important;
  }
}
.steps-container .buy-step:hover, .steps-container .buy-step.active {
  background: rgba(255, 255, 255, 0.15);
  transform: translateX(10px);
  border-color: rgba(255, 255, 255, 0.2);
}
.steps-container .buy-step:hover .step-number, .steps-container .buy-step.active .step-number {
  background: var(--bs-primary);
  color: white;
}
.steps-container .buy-step:hover h4, .steps-container .buy-step.active h4 {
  color: var(--bs-primary);
}
.steps-container .buy-step .step-number {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: all 0.3s ease;
}
@media (max-width: 768px) {
  .steps-container .buy-step .step-number {
    width: 32px;
    height: 32px;
    font-size: 0.9rem;
  }
}
.steps-container .buy-step h4 {
  font-size: 1.1rem;
  transition: color 0.3s ease;
}
@media (max-width: 768px) {
  .steps-container .buy-step h4 {
    font-size: 1rem;
  }
}
.steps-container .buy-step p {
  font-size: 0.9rem;
}
@media (max-width: 768px) {
  .steps-container .buy-step p {
    font-size: 0.8rem;
  }
}

.step-details {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 1rem;
  height: 100%;
  min-height: 300px;
}
@media (max-width: 992px) {
  .step-details {
    margin-top: 2rem;
  }
}
.step-details .step-icon {
  font-size: 2.5rem;
  color: var(--bs-primary);
  margin-bottom: 1.5rem;
}
@media (max-width: 768px) {
  .step-details .step-icon {
    font-size: 2rem;
  }
}
.step-details h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .step-details h3 {
    font-size: 1.3rem;
  }
}
.step-details p {
  opacity: 0.8;
  margin-bottom: 1.5rem;
}
@media (max-width: 768px) {
  .step-details p {
    font-size: 0.9rem;
  }
}

.faq-section {
  margin-top: 5rem;
}
.faq-section h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  .faq-section h3 {
    font-size: 1.5rem;
  }
}
.faq-section .custom-accordion {
  max-width: 800px;
  margin: 0 auto;
}
.faq-section .custom-accordion .accordion-item {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
}
.faq-section .custom-accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-section .custom-accordion .accordion-item .accordion-button {
  background: transparent;
  color: white;
}
.faq-section .custom-accordion .accordion-item .accordion-button:not(.collapsed) {
  background: rgba(255, 255, 255, 0.15);
}
.faq-section .custom-accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.faq-section .custom-accordion .accordion-item .accordion-button::after {
  filter: invert(1);
}
@media (max-width: 768px) {
  .faq-section .custom-accordion .accordion-item .accordion-button {
    padding: 1rem;
    font-size: 0.9rem;
  }
}
.faq-section .custom-accordion .accordion-item .accordion-body {
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  padding: 1.25rem;
  font-size: 0.95rem;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .faq-section .custom-accordion .accordion-item .accordion-body {
    padding: 1rem;
    font-size: 0.85rem;
  }
}

.btn-custom {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .btn-custom {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}
.btn-custom.primary {
  background: var(--bs-primary);
  color: white;
  border: none;
}
.btn-custom.primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(var(--bs-primary-rgb), 0.3);
}
.btn-custom.secondary {
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.btn-custom.secondary:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}/*# sourceMappingURL=App.css.map */