.giveaway-modal {
    .modal-content {
        background-color: #1a1a1a;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    }

    .modal-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .modal-footer {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .btn-primary {
        background: linear-gradient(45deg, #2193b0, #6dd5ed);
        border: none;
        
        &:hover {
            background: linear-gradient(45deg, #1c7a94, #5ab8cc);
        }
    }

    ul {
        padding-left: 1.2rem;
        
        li {
            margin-bottom: 0.5rem;
            color: #e0e0e0;
        }
    }

    .close {
        color: white;
        text-shadow: none;
        opacity: 0.7;
        
        &:hover {
            opacity: 1;
        }
    }
}
